import { checkConsentForPurpose } from "../integration/didomi";
import readMoreSlider from "./readMoreSlider";
export default async function mostReadSlider(context) {
  const container = context.getElementsByClassName("most-read-slider")[0];
  if (!container) return;
  const hasConsent = await new Promise(resolve => checkConsentForPurpose("marketing", resolve));
  const url = new URL("/read-more-recommendations", window.location.origin);
  url.searchParams.append("pathname", window.location.pathname);
  url.searchParams.append("articleId", window.Exp.bauTargeting.articleId);
  url.searchParams.append("hasConsent", hasConsent);
  const response = await fetch(url.href, {
    credentials: "same-origin"
  }).catch(() => null);
  if (!response || response.status !== 200) return;
  const html = await response.text();
  if (!html) return;
  container.innerHTML = html;
  const isFallback = response.headers.get("Is-Fallback") === "true";
  readMoreSlider(container, {
    isFallback
  });
}